<template>
    <div class="order">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <span>订单状态</span>
                    <el-radio-group v-model="searchVal.status" size="small">
                        <el-radio-button
                            label="未交付"
                            @click.native.prevent="clickStatus('未交付')"
                        ></el-radio-button>
                        <el-radio-button
                            label="部分交付"
                            @click.native.prevent="clickStatus('部分交付')"
                        ></el-radio-button>
                        <el-radio-button
                            label="已交付"
                            @click.native.prevent="clickStatus('已交付')"
                        ></el-radio-button>
                        <!-- <el-radio-button
                            label="异常"
                            @click.native.prevent="clickStatus('异常')"
                        ></el-radio-button> -->
                    </el-radio-group>
                    <span>时间</span>
                    <el-date-picker
                        v-model="searchVal.searchDate"
                        size="small"
                        type="daterange"
                        style="margin-left: 0; width: 353px"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                        :picker-options="expireTimeOption"
                        clearable
                        @change="getTableData"
                    >
                    </el-date-picker>
                    <span>购买类型</span>
                    <el-select
                        v-model="searchVal.orderType"
                        size="small"
                        style="width: 180px"
                        clearable
                        placeholder="全部"
                        @change="getTableData()"
                    >
                        <el-option label="正式" value="1"></el-option>
                        <el-option label="续费" value="2"></el-option>
                        <el-option label="增购" value="3"></el-option>
                    </el-select>
                    <span>查询</span>
                    <el-input
                        size="small"
                        style="width: 180px"
                        placeholder="订单编号、客户名称"
                        v-model="searchVal.name"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="getTableData"
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                        style="margin-right: 0"
                    ></el-button>
                </p>
            </div>
            <div class="search-two">
                <!-- <clue-product
                    ref="clueproduct"
                    @changeProductId="changeProductId"
                    :productWidth="215"
                ></clue-product> -->
                <Department
                    ref="department"
                    :departmentWidth="150"
                    :adminWidth="150"
                    :depSearch="btnP.depSearch"
                    :searchUser="btnP.userSearch"
                    @searchData="searchData"
                ></Department>
                <span
                    v-if="
                        (btnP.userSearch &&
                            !btnP.depSearch &&
                            searchVal.adminId) ||
                        (!btnP.userSearch && !btnP.depSearch) ||
                        (btnP.userSearch && btnP.depSearch && searchVal.adminId)
                    "
                    >销售订单</span
                >
                <el-radio-group
                    v-if="
                        (btnP.userSearch &&
                            !btnP.depSearch &&
                            searchVal.adminId) ||
                        (!btnP.userSearch && !btnP.depSearch) ||
                        (btnP.userSearch && btnP.depSearch && searchVal.adminId)
                    "
                    v-model="searchVal.adminValText"
                    size="small"
                >
                    <el-radio-button
                        label="我的"
                        @click.native.prevent="clickStatus2('我的')"
                    ></el-radio-button>
                    <el-radio-button
                        label="合作"
                        @click.native.prevent="clickStatus2('合作')"
                    ></el-radio-button>
                    <el-radio-button
                        label="协作"
                        @click.native.prevent="clickStatus2('协作')"
                    ></el-radio-button>
                </el-radio-group>
                <span v-if="btnP.ordersource">订单来源</span>
                <el-radio-group
                    v-if="btnP.ordersource"
                    v-model="searchVal.sourceType"
                    size="small"
                >
                    <el-radio-button
                        label="渠道"
                        @click.native.prevent="clickOrderby('渠道')"
                    ></el-radio-button>
                    <el-radio-button
                        label="直销"
                        @click.native.prevent="clickOrderby('直销')"
                    ></el-radio-button>
                </el-radio-group>

                <span>有无实收</span>
                <el-radio-group
                    v-model="searchVal.isNet"
                    size="small"
                >
                    <el-radio-button
                        label="有"
                        @click.native.prevent="clickIsNet('有')"
                    ></el-radio-button>
                    <el-radio-button
                        label="无"
                        @click.native.prevent="clickIsNet('无')"
                    ></el-radio-button>
                </el-radio-group>
            </div>
        </div>
        <div class="table-title">
            <p>订单列表</p>
            <span>
                <button
                    class="cancel"
                    @click="onOrderChannel"
                    v-if="btnP.channelorder"
                >
                    渠道订单
                </button>
                <button
                    class="cancel"
                    @click="onOrderChannelEmail"
                >
                    渠道订单发邮件
                </button>

                <button
                    class="cancel"
                    @click="onOrderChannelEmail"
                >
                    直销订单发邮件
                </button>
                <!-- <button
                    class="cancel"
                    @click="orderRefresh"
                    v-if="btnP.refresh"
                >
                    刷新状态
                </button> -->
            </span>
        </div>
        <WyTable
            ref="table"
            @onAdd="onAdd"
            @totalNum="totalNum"
            @onDetail="onDetail"
            @onEdit="onEdit"
            @searchEmpty="getData"
            @onReminder="onReminder"
        ></WyTable>
        <div class="page-box">
            <el-pagination
                v-if="isPage"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 新增 -->
        <el-drawer
            :visible.sync="drawerAdd"
            :direction="direction"
            :before-close="handleDrawerClose"
            size="500px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    新增交付计划
                </div>
            </template>
            <Add ref="add" @close="handleDrawerClose"></Add>
        </el-drawer>
        <!-- 交付 -->
        <el-dialog
            :visible.sync="dialogEdit"
            width="640px"
            :before-close="handleDialogClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    编辑
                </div>
            </template>
            <div style="width: 60%; margin: 20px auto 140px">
                <el-form label-position="left" label-width="80px" size="small">
                    <el-form-item label="结算价" required>
                        <el-input
                            v-model="orderForm.settleAmount"
                            placeholder="请输入结算价"
                            @input="
                                () => {
                                    orderForm.settleAmount =
                                        orderForm.settleAmount.replace(
                                            /[^0-9]/g,
                                            ''
                                        );
                                }
                            "
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="指导价" required>
                        <el-input
                            v-model="orderForm.guideAmount"
                            placeholder="请输入指导价"
                            @input="
                                () => {
                                    orderForm.guideAmount =
                                        orderForm.guideAmount.replace(
                                            /[^0-9]/g,
                                            ''
                                        );
                                }
                            "
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button round @click="handleDialogClose" size="mini"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    round
                    @click="onSubmitEidtOrder"
                    size="mini"
                    >保 存</el-button
                >
            </div>
        </el-dialog>
        <!-- 交付 -->
        <el-dialog
            :visible.sync="dialogChannel"
            width="640px"
            :before-close="handleDialogClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    渠道订单
                </div>
            </template>
            <OrderChannel ref="channel"></OrderChannel>
            <div slot="footer" class="dialog_footer">
                <el-button round @click="handleDialogClose" size="mini"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    round
                    @click="onSubmitChannelOrder"
                    size="mini"
                    >保 存</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { roleList } from '@/api/user/clue';
import { orderUpdate, orderUpdateStatus,orderChannelEmail } from '@/api/order/order.js';
import { orderAdd } from '@/api/user/business/contract.js';
import { departmentList } from '@/api/admin/framework/framework.js';
import WyTable from './table/wytable';
// import TmTable from './table/table';
import Add from './add/add.vue';
import Department from '../../../components/Department';
import clueProduct from '../../../components/clueProduct.vue';
import OrderChannel from './orderchannel/orderchannel.vue';
export default {
    components: {
        // Details,
        WyTable,

        Add,
        Department,
        clueProduct,
        OrderChannel,
    },
    data() {
        return {
            btnP: {},
            searchVal: {
                status: '',
                name: '',
                productCategory: '',
                adminValText: '我的',
                sourceType: '',
                isNet: '',
                searchDate: [],
            },
            dempData: [],
            userData: [],
            currentPage: 1,
            pagesize: 20,
            total: 0,
            tableRow: {},
            direction: 'rtl',
            drawerAdd: false,
            dialogEdit: false,
            dialogChannel: false,
            departmentList: [],
            orderForm: {
                settleAmount: 0,
                guideAmount: 0,
            },
            isPage: false,
        };
    },

    mounted() {
        if (sessionStorage.getItem('pageData')) {
            this.currentPage = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageNum;
            this.pagesize = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageSize;
            this.searchVal = JSON.parse(
                sessionStorage.getItem('pageData')
            ).param;
            // this.$refs.clueproduct.$data.productId =
            //     this.searchVal.productCategory;
            this.$refs.department.$data.departmentId =
                JSON.parse(sessionStorage.getItem('pageData')).param
                    .departmentId || undefined;
            this.$refs.department.$data.adminId =
                JSON.parse(sessionStorage.getItem('pageData')).param.adminId ||
                '';
            if (this.searchVal.departmentId) {
                this.$refs.department.getUserList(
                    JSON.parse(sessionStorage.getItem('pageData')).param
                        .departmentId
                );
            }
            sessionStorage.removeItem('pageData');
            this.isPage = true;
        } else {
            this.isPage = true;
            if (this.$route.query.name) {
                this.searchVal.name = this.$route.query.name;
            }
        }
        this.getBtn();
    },
    methods: {
        orderRefresh() {
            let data = {
                param: {},
            };
            orderUpdateStatus(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('销售订单状态已更新');
                }
            });
        },
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
                if (btnArr[i].children && btnArr[i].children.length > 0) {
                    btnArr[i].children.forEach((item) => {
                        btn['' + item.route] = true;
                        if (item.children && item.children.length > 0) {
                            item.children.forEach((itm) => {
                                btn['' + itm.route] = true;
                            });
                        }
                    });
                }
            }
            this.btnP = btn;
            // if (btn.aliMail) {
            //     this.$refs.clueproduct.$data.productId =
            //         this.searchVal.productCategory = 2;
            //     this.$refs.clueproduct.getData(true);
            // } else {
            //     this.$refs.clueproduct.getData();
            // }
            if (btn.depSearch) {
                this.getDepartmentList();
            }
            if (btn.userSearch && !btn.depSearch) {
                this.$refs.department.getUserList(
                    sessionStorage.getItem('departmentId')
                );
            }

            this.getData();
        },
        // changeProductId(val) {
        //     this.pagesize = 20;
        //     this.currentPage = 1;
        //     this.searchVal.productCategory = val;
        //     this.getData();
        // },
        onOrderChannel() {
            this.dialogChannel = true;
            setTimeout(() => {
                this.$refs.channel.getData();
            }, 0);
        },
        getData() {
            this.$refs.table.getData(
                this.searchVal,
                this.currentPage,
                this.pagesize,
                this.btnP
            );
        },
        onOrderChannelEmail(){
            let data = {
                param: {
                }
            };
            if (this.searchVal.name) {
                data.param.name = this.searchVal.name;
            }
            if (this.searchVal.status == '未交付') {
                data.param.status = 1;
                // data.param.contractStatusList = [7, 8, 10, 11, 12];
            } else if (this.searchVal.status == '部分交付') {
                data.param.status = 2;
                // data.param.contractStatusList = [7, 8, 10, 11, 12];
            } else if (this.searchVal.status == '已交付') {
                data.param.status = 3;
                // data.param.contractStatusList = [7, 8, 10, 11, 12];
            } else if (this.searchVal.status == '异常') {
                data.param.contractStatus = 3;
                data.param.statusList = [1, 2, 3];
            } else {
                data.param.statusList = [1, 2, 3];
                // data.param.contractStatusList = [7, 8, 10, 11, 12];
            }
            if (!this.btnP.depSearch && this.searchVal.adminValText == '我的') {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            }
            if (!this.btnP.userSearch && !this.btnP.depSearch) {
                if (this.searchVal.adminValText == '我的') {
                    data.param.adminId = sessionStorage.getItem('adminId');
                } else if (this.searchVal.adminValText == '合作') {
                    data.param.collaboratorAdminId =
                        sessionStorage.getItem('adminId');
                } else if (this.searchVal.adminValText == '协作') {
                    data.param.cooperationAdminId =
                        sessionStorage.getItem('adminId');
                }
            }

            data.param.productCategory = 4;

            if (this.searchVal.departmentId && this.searchVal.adminValText == '我的') {
                data.param.departmentId = this.searchVal.departmentId;
            }
            if (this.searchVal.adminId) {
                if (this.searchVal.adminValText == '我的') {
                    data.param.adminId = this.searchVal.adminId;
                } else if (this.searchVal.adminValText == '合作') {
                    data.param.collaboratorAdminId = this.searchVal.adminId;
                } else if (this.searchVal.adminValText == '协作') {
                    data.param.cooperationAdminId = this.searchVal.adminId;
                }
            }
            if (this.searchVal.orderType) {
                data.param.orderType = this.searchVal.orderType;
            }
            if (this.searchVal.sourceType == '渠道') {
                data.param.sourceType = 2;
                delete data.param.contractStatusList;
            } else if (this.searchVal.sourceType == '直销') {
                data.param.sourceType = 1;
            }
            if (this.searchVal.isNet == '有') {
                data.param.isNet = 1;
                delete data.param.contractStatusList;
            } else if (this.searchVal.isNet == '无') {
                data.param.isNet = 0;
            }
            
            if (!this.btnP.ordersource) {
                data.param.sourceType = 1;
            }
            if (this.searchVal.createMonth) {
                data.param.createMonth = this.searchVal.createMonth;
            }
            if (this.searchVal.searchDate) {
                data.param.startCreateDate = this.searchVal.searchDate[0];
                data.param.endCreateDate = this.searchVal.searchDate[1];
            }
            orderChannelEmail(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('发送成功');
                }
            });
        },
        getTableData() {
            this.currentPage = 1;
            this.getData();
        },
        clickStatus(e) {
            e === this.searchVal.status
                ? (this.searchVal.status = '')
                : (this.searchVal.status = e);
            this.getTableData();
        },
        clickStatus2(e) {
            this.searchVal.adminValText = e;
            this.getTableData();
        },
        clickOrderby(e) {
            this.searchVal.sourceType == e
                ? (this.searchVal.sourceType = '')
                : (this.searchVal.sourceType = e);
            this.getTableData();
        },
        clickIsNet(e) {
            this.searchVal.isNet == e
                ? (this.searchVal.isNet = '')
                : (this.searchVal.isNet = e);
            this.getTableData();
        },
        totalNum(num) {
            this.total = num;
        },
        searchData(data) {
            this.pagesize = 10;
            this.pageNum = 1;
            this.searchVal.departmentId = data.departmentId;
            this.searchVal.adminId = data.adminId;
            this.searchVal = JSON.parse(JSON.stringify(this.searchVal));
            this.getData();
        },
        onDetail(row) {
            if (row.contractId) {
                this.$router.push({
                    path: '/condetails',
                    query: {
                        index: 4,
                        id: row.contractId,
                    },
                });
            }
        },
        getUser(departmentId) {
            let data = {
                param: {
                    departmentId: departmentId,
                    roleTypeList: [1, 2, 3, 4, 8, 9, 10],
                },
            };
            roleList(data).then((res) => {
                this.userData = res.data;
            });
        },
        //新增
        onAdd(row) {
            this.drawerAdd = true;
            setTimeout(() => {
                this.$refs.add.getData(row);
            }, 0);
        },
        onEdit(row) {
            this.dialogEdit = true;
            this.tableRow = row;
            this.orderForm.settleAmount = row.settleAmount;
            this.orderForm.guideAmount = row.guideAmount;
        },
        onSubmitEidtOrder() {
            let data = {
                param: {
                    id: this.tableRow.id,
                    guideAmount: Number(this.orderForm.guideAmount),
                    settleAmount: Number(this.orderForm.settleAmount),
                },
            };
            if (!Number(this.orderForm.guideAmount)) {
                return this.$message.error('请输入指导价');
            }
            if (!Number(this.orderForm.settleAmount)) {
                return this.$message.error('请输入结算价');
            }

            orderUpdate(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.handleDialogClose();
                }
            });
        },
        // 获取部门
        getDepartmentList() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.departmentList = res.data;
                    this.$refs.department.getData(this.btnP, res.data);
                }
            });
        },
        // 获取部门成员
        getUserList(departmentId) {
            this.searchVal.followAdminId = '';
            let data = {
                param: {
                    departmentId,
                },
            };
            roleList(data).then((res) => {
                this.userData = res.data;
            });
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.currentPage = 1;
            this.getData();
        },
        searchEmpty() {
            this.searchVal = {
                status: '',
                name: '',
                productCategory: '',
                adminValText: '我的',
            };

            // if (this.btnP.aliMail) {
            //     this.searchVal.productCategory = '2';
            // }
            if (this.btnP.depSearch || this.btnP.userSearch) {
                this.$refs.department.departmentId = undefined;
                this.$refs.department.adminId = '';
                this.$refs.department.userName = [];
            }
            this.getData();
        },
        onSubmitChannelOrder() {
            let from = this.$refs.channel.form;
            let fieldList = this.$refs.channel.fieldList;
            if (!from.customerId) {
                return this.$message.error('请选择客户');
            }
            if (!from.adminId) {
                return this.$message.error('请选择渠道');
            }

            for (let j = 0; j < fieldList.length; j++) {
                if (!fieldList[j].attributeColumnName) {
                    if (
                        (from.productCategory == 1 ||
                            from.productCategory == 2 ||
                            from.productCategory == 4) &&
                        from.productType == 3 &&
                        fieldList[j].attributeColumnName == 'term'
                    ) {
                    } else {
                        return this.$message.error(
                            fieldList[j].attributeName + '不能为空'
                        );
                    }
                }
            }
            let data = {
                param: {
                    ...from,
                    orderType: from.productType,
                    sourceType: 2,
                    orderNumber: from.number,
                    orderTerm: from.term,
                },
            };
            orderAdd(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.handleDialogClose();
                }
            });
        },
        onReminder(row) {
            let data = {
                param: {
                    id: row.id,
                    reminderStatus: 2,
                },
            };
            orderUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.getData();
                }
            });
        },
        handleDrawerClose() {
            this.drawerAdd = false;
            this.getData();
        },
        handleDialogClose() {
            this.orderForm = {
                settleAmount: 0,
                guideAmount: 0,
            };
            this.dialogEdit = false;
            this.dialogChannel = false;
            this.getData();
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.order {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 500;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                button {
                    background: #2370eb;
                    border-radius: 2px;

                    margin-right: 16px;
                }
            }
        }
        .search-two {
            padding-bottom: 10px;
        }
    }
    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        font-size: 14px;
        font-weight: 600;
        .add {
            border-color: #d9d9d9;
            color: #333333;
        }
        p {
            color: #333333;
            line-height: 20px;
        }

        button {
            font-size: 12px;
            padding: 0 7px 0 12px;
            height: 24px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2370eb;
            cursor: pointer;
            color: #2370eb;
            margin-left: 16px;
            text-align: center;
        }

        // button:hover {
        //     background: #2370eb;
        //     color: #ffffff;
        //     border-color: #2370eb;
        // }
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
}
</style>
